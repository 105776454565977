import { truncate, dateFormater } from '@/filters'

export default {
  name: 'ArticleItem',
  filters: {
    truncate,
    dateFormater
  },
  props: {
    article: {
      type: Object,
      default: {}
    }
  },
  methods: {
    handleDeleteArticle () {
      this.$emit('deleteArticle', this.article.id)
    },
    editArticle () {
      this.$emit('editArticle', this.article.id)
    }
  }
}
