import { app } from '@/config'

const truncate = (text) => {
  return text.substring(0, 60) + '...'
}

const dateFormater = (value) => {
  const date = new Date(value)
  return `${date.getDate()} ${app.months[date.getMonth()]} ${date.getFullYear()}`
}

export {
  truncate,
  dateFormater
}
